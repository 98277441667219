import React, { Component } from 'react';
import { Accordion, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import {isCoordinator, showMessage ,userPermissions } from '../../ToReviewDetails/commonFunctions';
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import NewTranslationalEx from '../../NewTranslationalEx'
import Loader from '../../common/loader/Loader';
import { DIAGNOSTIC_PROPOSAL } from '../../../mapping';
import UserContext from '../../../context/UserContext';
import { dateChange, dateStringformat, dateTimeformat, getUserInfo, uploadFile,getPrintViewTextBox } from '../../../utility/helperFunctions';
import { INDICATIONS } from "../../../consts";
import History from './section-2/History';
import FileComponentEx from '../../common/file-component/FileComponentEx';
import swal from 'sweetalert';
import '../../common/pdf.css'
import axios from 'axios';
import DTAButton from '../../common/Button/DTAButton';
import * as IIRBtn from '../../common/Button';
import { OBJ_TYPES } from '../../common/SwitchHistory/historyConsts';
import SwitchHistory from '../../common/SwitchHistory';
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div     
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        e.stopPropagation();
      }}
    >
      {children}
    </div>
  ));

export default class TranslationalDetailsEx extends Component {

    static contextType = UserContext;
    titleRef = React.createRef();
    state={
        externalurl :  "http://localhost:1337",
        showLoader : false,
        showLoaderUpload : false ,
        action : "",
        historyRefresh:true,
        loadTable : false,
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        recordID:"",
        submissionId: "",
        deleted:"",
        diagnostic:"",
        currencyISOCode:"",
        createdDate:"",
        createdByID :"",
        lastModifiedDate:"",
        LastModifiedByID:"",
        systemModstamp:"",
        lastActivityDate:"",
        submission:"",
        assayMethod:"None",
        assayName:"",
        assayRegulatoryStatus:"None",
        lab_iso_certified:"None",
        cityCoinvestigator:"",
        coinvestigator_country:"",
        coinvestigator_state:"",
        emailAddressCoInvestigator:"",
        legal_representative_email:"",
        legal_represent_phoneno:"",
        emailAddressforSafetyMailNotification:"",
        first_name_coinvestigator:"",
        first_name_legal_represent:"",
        unique_personal_clinical_sample:"None",
        coinvestigator_institution:"",
        isAnotherentitythesponsorofthisst:"",
        ext_assessment_program:"None",
        biobank_owner:"None",
        lastNameCoInvestigator:"",
        lastNamelegal:"",
        cityLegalRepresentative:"",
        legal_represent_country: "",
        legal_represent_state:[],
        methods_study_design:"",
        middleInitiallcoinvestigator:"",
        middleInitialLegal:"",
        other_project_timeline_info:"",
        phoneNumberCoInvestigator:"",
        legal_representative_email:"",
        platform:"",
        primary_obj_hypothesis:"",
        project_study_complete_date:"",
    project_study_start_date:"",
    project_publication_date:"",
    prj_report_submission_date:"",
    project_study_length:"",
    prj_study_milestone_timing:"",
        publication_strategy:"",
        translational_references:"",
        regulatoryApproved:"None",
        sampleDescription:"None",
        pleaseDescribeIfOther1:"",
        pleaseDescribeIfOther2:"",
        pleaseDescribeIfOther3:"",
        scientific_rationale:"",
        secondary_objective:"",
        source_of_samples:"None",
        specialty:"",
        coinvestigator_streetaddress:"",
        streetAddressLegalRepresentative:"",
        study_report_req_explain:"",
        study_title:"",
        studyType:"None",
        target_start_date:null,
        target_complete_date:null,
        specific_timing_req:"",
        testingTiming:"None",
        tumor_type:"None",
        zipCodeCoInvestigator:"",
        legal_represent_zipcode:"",
        evidenceOfAssayValidation:"",
        final_study_report:"None",
        if_any_coinvestigators:"None",
        desc_laboratory_certification:"",
        medicalLicenseNumber:"",
        raw_data_req_explain:"",
        raw_data_requested:"None",
        research_funding_request:"None",
        successFile: false,
        errorFile: false,
        successModal: false,
        errorModal: false,
        countryList:[],
        stateListLegal: [],
        stateListCoinvestigator: [],
        proposalDetails: {},
        fileList: [],
        patchObject: {},
        selectedFile: null
    }
    get inputFieldsArrayInformation() {
        return[
           
             {
                 label: "Translational Submitted",
                 type: "checkbox",
                 name: "is_translational_submitted",
             },
             {
                 label: "Submission",
                 type: "text",
                 name: "submission_Id",
                 disabled: true
             },
             {
                 label: "Created By",
                 type: "text",
                 name: "createdByID",
                 className:"createUpdateByfontsize",
                 disabled: true
             },
             {
                 label: "Last Modified By",
                 type: "text",
                 name: "LastModifiedByID",
                 className:"createUpdateByfontsize",
                 disabled: true
             },     
        ]
    }
    get inputFieldsArrayLegalRepresentative() {
        return[
           
             {
                label: "[EXT] First Name Legal Representative",
               type: "text",
                name: "first_name_legal_represent",
            },
            {
                label: "[EXT] Middle Initial Legal",
                type: "text",
                name: "legal_represent_middle_initial",
                
            },
            {
                label: "[EXT] Last Name Legal",
                type: "text",
                name: "legal_represent_lastname"
            },
            {
                label: "[EXT] Street Address Legal Representative",
                type: "text",
                name: "legal_represent_streetaddress"
            },
            
            {
                label: "[EXT] City Legal Representative",
                type: "text",
                name: "legal_represent_city",
            },
            {
                label: "[EXT] Country Legal",
                type: "searchableDropdown",
                placeholder: "Select Country legal",
                name: "legal_represent_country",
                options: this.state.countryList,
               onSelect: this.onCountryChange
            },
            {
                label: "[EXT] State Legal Representative",
                type: "searchableDropdown",
                placeholder: "Select [EXT] State Legal Representative",
                name: "legal_represent_state",
                options: this.state.stateListLegal,
                onSelect: this.onSearchSelect
            },
            {
                label: "[EXT] Zip Code Legal Representative",
                type: "text",
                name: "legal_represent_zipcode",
               
            },
            {
                label: "[EXT] Phone Number Legal Representative",
                type: "text",
                name: "legal_represent_phoneno",
               
            },
            {
                label: "[EXT] Email Address Legal Representative",
                type: "email",
                name: "legal_representative_email",
               
            },
        ]
    }
    get inputFieldsArrayCoInvestigator() {
        return[
            
             {
                label: "[EXT] If any Co-Investigators",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "if_any_coinvestigators",
                options: [
                    "None",
                    "Yes",
                    "No"
                ]
               
            },
            {
                label: "[EXT] Middle Initial Co-Investigator",
                type: "text",
                name: "coinvestigator_middle_initial",
               
            },
            {
                label: "[EXT] First Name Co-Investigator",
                type: "text",
                name: "first_name_coinvestigator",
               
            },
            {
                label: "[EXT] Institution",
                type: "text",
                name: "coinvestigator_institution",
               
            },
            {
                label: "[EXT] Last Name Co-Investigator",
                type: "text",
                name: "coinvestigator_lastname",
               
            },
            {
                label: "[EXT] City Co-Investigator",
                type: "text",
                name: "coinvestigator_city",
               
            },
            {
                label: "[EXT] Street Address Co-Investigator",
                type: "text",
                name: "coinvestigator_streetaddress",
               
            },
            {
                label: "[EXT] Co-Investigator State",
                type: "searchableDropdown",
                placeholder: "Select CoInvestigator State",
                name: "coinvestigator_state",
                options: this.state.stateListCoinvestigator,
                onSelect: this.onSearchSelect
               
            },
            {
                label: "[EXT] Country Co-Investigator",
                type: "searchableDropdown",
                placeholder: "Select Country",
                name: "coinvestigator_country",
                options: this.state.countryList,
                onSelect: this.onCountryChange
               
            },
            {
                label: "[EXT] Phone Number Co-Investigator",
                type: "text",
                name: "coinvestigator_phoneno",
               
            },
            {
                label: "[EXT] Zip Code Co-Investigator",
                type: "text",
                name: "coinvestigator_zipcode",
               
            },
            {
                label: "[EXT] Email Address Co-Investigator",
                type: "email",
                name: "coinvestigator_email",
               
            },
        ]
    }
    get inputFieldsArrayTargetDates() {
        return[
            
             {
                label: "Target Starting Date",
                type: "date",
                name: "target_start_date",
               
            },
            {
                label: "Target Completing Date",
                type: "date",
                name: "target_complete_date",
               
            },
            {
                label: "Specific Timing Requirements",
                type: "textarea",
                name: "specific_timing_req",
                helptext: "If you have any specific timing requirements for receiving approval and/or compound, please explain"
            },
        ]
    }
    get inputFieldsArrayStudyTitle() {
        return[
          
             {
                label: "[EXT] Study Title",
                type: "text",
                name: "study_title",
               
            },
        ]
    }
    get inputFieldsArrayPrimaryObjectives() {
        return[
           
             {
                label: "[EXT] Primary Objective/Hypothesis",
                type: "textarea",
                name: "primary_obj_hypothesis",
                
            },
        ]
    }
    get inputFieldsArraySecondaryObjjectives() {
        return[
           
             {
                label: "[EXT] Secondary Objective",
                type: "textarea",
                name: "secondary_objective",
                
            },
        ]
    }
    get inputFieldsArrayScientificRationale() {
        return[
           
             {
                label: "[EXT] Scientific Rationale",
                type: "textarea",
                name: "scientific_rationale",
                
            },
        ]
    }
    get inputFieldsArrayMethods() {
        return[
            
             {
                label: "[EXT] Methods",
                type: "textarea",
                name: "methods_study_design",
               
            },
        ]
    }
    get inputFieldsArrayTestDetail()  { 
        return[
    {
        label: "[EXT] Study to use animals",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "is_study_use_animal",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    {
        label: "[EXT] Lilly Study Of Interest",
        type: "text",
        name: "study_of_interest",
        
    },
    {
        label: "Requesting Access To Lilly Data",
        type: "dropdown",
        name: "is_accesss_to_lilly_data",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    {
        label: "Type Of Requested Analysis",
        type: "dropdown",
        name: "type_of_req_analysis",
        options: [
            "Lilly analysis of existing data",
            "access to data",
            "new Lilly analysis of samples",
            "access to samples"
        ]
    },
    
    {
        label: "[EXT] IACUC Documentation attached?",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "is_iacuc_doc_require",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    {
        label: "Who will provide the human samples?",
        type: "dropdown",
        name: "provide_human_sample",
        options: [
            "Institution’s Biobank",
            "Third Party",
        ]
    },
    {
        label: "Is There A Preclinical Component?",
        type: "dropdown",
        name: "is_preclinical_component",
        options: [
            "None",
            "Yes",
        "No"    ]
    },
    {
        label: "[EXT] Description of Animal Used",
        type: "textarea",
        name: "desc_of_animal_used"
    },
    {
        label: "[EXT] Doses/Concentrations",
        type: "textarea",
        name: "dose_concentration"
    },
    {
        label: "[EXT] AC&U Committee approval?",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "is_animalcare_committee_approval",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    {
        label: "[EXT] Model/Methodology",
        type: "textarea",
        name: "model_methodology"
    },
    {
        label: "[EXT] Tumor Type",
            type: "multi-select",
            placeholder: "Select Tumor Type",
            name: "tumor_type",
            options: INDICATIONS,
            disabled:this.state.therapeuticArea !=="Oncology"
        },
        {
            label: "[EXT] Indication",
                type: "text",
                name: "indication",
                disabled:this.state.therapeuticArea !=="Diabetes & Obesity"
        },
    {
        label: "[EXT] Statistical Analysis",
        type: "textarea",
        name: "statistical_analysis"
    },
    {
        label: "[EXT] Human samples involved",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "is_human_sample_involved",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    {
        label: "[EXT] Type of Human Samples",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "human_sample_type",
        options: [
            "None",
            "Cells From Biobank",
            "Study Specific"
        ]
    },
    {
        label: "[EXT] Lilly Compound of Interest",
        type: "text",
        name: "compound_of_interest",
    },
    {
        label: "[EXT] How much compound is requested?",
        type: "text",
        name: "amount_of_compound_req",
    },
    {
        label: "[EXT] Is Lilly Compound Requested?",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "is_lilly_compound_requested",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    {
        label: "[EXT] Shipping Import License Required?",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "shipping_import_license",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    ]}
    get inputFieldsArrayOperationalInformation() {
        return[
            
             {
                label: "[EXT] Source of samples",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "source_of_samples",
                options: [
                    "None",
                    "Biobank",
                    "Collected specially for this study"
                ]                
            }, 
            {
                label: "[EXT] Unique, Personal, Clinical samples",
                type: "dropdown",
                name: "unique_personal_clinical_sample",
                options: [
                    "None",
                    "Yes",
                    "No"
                ]                
            }, 
            {
                label: "[EXT] Biobank owner",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "biobank_owner",
                options: [
                    "None",
                    "Biobank",
                    "Third Party"
                ]                
            },
            {
                label: "[EXT] Is Lab CLIA/ISO certified",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "lab_iso_certified",
                options: [
                    "None",
                    "Yes",
                    "No",
                   "Not Applicable"                   
                ],
                helptext: "Is the laboratory CLIA/ISO certified?"                         
            },
            {
                label: "[EXT] Lab External Quality Assessment Program",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "ext_assessment_program",
                options: [
                    "None",
                    "Yes",
                    "No"
                ],
                helptext: "Does the performing laboratory participate in an external quality assessment program?"               
            }, 
            {
                label: "[EXT] Laboratory's certifications Description",
                type: "textarea",
                name: "desc_laboratory_certification",
               
            },

        ]
    }
    get inputFieldsArrayTimeline() {
        return[
           
             {
                label: "[EXT] Projected study length",
                type: "number",
                name: "project_study_length",
               
            },
            {
                label: "[EXT] Projected Study Start Date",
                type: "date",
                name: "project_study_start_date",
               
            },
            {
                label: "[EXT] Projected Study Complete Date",
                type: "date",
                name: "project_study_complete_date",
               
            },
            {
                label: "[EXT] Projected timing for key study milestone",
                type: "text",
                name: "prj_study_milestone_timing",
               
            },
            {
                label: "[EXT] Projected date for study report submission",
                type: "date",
                name: "prj_report_submission_date",
               
            },
            {
                label: "[EXT] Projected date for publication",
                type: "date",
                name: "project_publication_date",
               
            },
            {
                label: "[EXT] Other helpful project timeline information",
                type: "textarea",
                name: "other_project_timeline_info",
               
            },
        ]
    }
    get inputFieldsArrayResearchFunding() {
        return[
           
             {
                label: "[EXT] Requesting Research Funding",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "research_funding_request",
                options: [
                    "None",
                    "Yes",
                    "No"
                ]
                
            },
        ]
    }
    get inputFieldsArrayStudyReport(){
        return[
           
             {
                label: "[EXT] Final study report",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "final_study_report",
                options: [
                    "None",
                    "Yes",
                    "No"
                ]
                
            }, 
            {
                label: "[EXT] Study Report Request explain",
                type: "textarea",
                name: "study_report_req_explain",
               
            },

            //  *************
          

        ]
    }
    get inputFieldsArrayBriefDescription() {
        return[
            
             {
                label: "[EXT] Publication Strategy",
                type: "textarea",
                name: "publication_strategy",
               
            },
            // **************
           
        ]
    }
    get inputFieldsArrayRawData(){
        return[
           
             {
                label: "[EXT] Raw Data Requested",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "raw_data_requested",
                options: [
                    "None",
                    "Yes",
                    "No"
                ]
                
               
            }, 
            {
                label: "[EXT] Raw Data If Requested Explain",
                type: "textarea",
                name: "raw_data_req_explain",
               
            }, 
        ]
    }
        get inputFieldsArrayReferences() {
            return [
           
                 {
                    label: "[EXT] References",
                    type: "textarea",
                    name: "translational_references",
                   
                },
            ];
        }  
    
        async getExternalUrl() {
            let response ='';
            try {
                 response = await API.get(`user/externalurl`);
            }
            catch (err) {
                console.log(err);
            }
            this.setState({
            externalurl : (response && response.data) ? response.data.exturl : "http://localhost:1337",
        })
        }
        getErrorDescriptions=()=>{

            let {error,proposallist}=this.state;
        
            let allFields=[...this.inputFieldsArrayRawData,
                ...this.inputFieldsArrayReferences,
             ...this.inputFieldsArrayBriefDescription,
            ...this.inputFieldsArrayStudyReport,
        ...this.inputFieldsArrayResearchFunding,
    ...this.inputFieldsArrayTimeline,...this.inputFieldsArrayOperationalInformation,...this.inputFieldsArrayTestDetail,
        ...this.inputFieldsArrayMethods,...this.inputFieldsArrayScientificRationale,
        ...this.inputFieldsArraySecondaryObjjectives,
        ...this.inputFieldsArrayStudyTitle,...this.inputFieldsArrayTargetDates,...this.inputFieldsArrayCoInvestigator,
        ...this.inputFieldsArrayLegalRepresentative,...this.inputFieldsArrayInformation];
        
            let messages = [];
        
            for (let [key, value] of Object.entries(error)) {
        
                let exist = allFields.find(p => p.name === key);
        
                if (exist) {
        
                    let messageText=exist.label;
                    if(error["lengthValidation"][key]){
                        messageText=messageText+' - Maximum Length should be less than '+proposallist[key]+" chars"
                    }
                    messages.push(messageText);
        
                }
        
            }
        
            return <ul>
        
                {messages&&messages.map((item,index)=>{
        
                    return <li>{item}</li>
        
                })}
        
            </ul>}
              formValidation = () => {
                let is_valid = true;
               
                let{proposallist}=this.state;
                
                const obj = ({ ...this.state.patchObject });
                let error={};
                error["lengthValidation"]={};
                for (let [key, value] of Object.entries(proposallist)) {
                    if(value){
                        if(this.state[key]&&this.state[key].length>value){
                            is_valid = false;
                            error[key] = true;
                            error["lengthValidation"][key]=true;
                        }
                    }
                }
                this.setState({error})
        
                return is_valid;
            }
            formType={
                INITIATE_INSTRUCTION:'INITIATE_INSTRUCTION'
            }
    async componentDidMount() {
        let res=await API.get('reviewConcept/schemaInformation/translational_proposals')

        // console.log(res)

        this.setState({

            proposallist:res.data

        })
        await this.getExternalUrl();
        let _isCoordinator=isCoordinator(this.context);
            let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
            this.setState({
                showLoader : true,
                isCoordinator:_isCoordinator,
                action :"fetching",
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("global_coordinator")  ||  selectedRole.includes("administrator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
                    delete:selectedRole.includes("global_coordinator")  || selectedRole.includes("administrator"),
                },
            });
            this.getTranslationDetails(this.props.submissionId)
          }
        componentDidUpdate(previousProps, previousState) {
            // console.log(this.context.selectedRole);
            // console.log(previousState.selectedRole);
            let _isCoordinator=isCoordinator(this.context);
            let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
            if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
                this.setState({
                    selectedRole: selectedRole,
                    isCoordinator:_isCoordinator,
                    access: {
                        view: true,
                        create:  selectedRole.includes("global_coordinator")  || selectedRole.includes("administrator"),
                        edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
                        delete: selectedRole.includes("global_coordinator")  ||selectedRole.includes("administrator"),
                    },
                }, () => { console.log("userRole", this.state.selectedRole) })
            }
        }
    
    
        getTranslationDetails = async () => {
            const submissionId = this.props.submissionId;
            if(submissionId){
                const res = await API.get(`/translationalProposal/submission/${submissionId}`);
                this.setState({
                    proposalDetails: res.data,
                    patchObject:{"id":res.data.id}
                }, this.getCountryList);
              await  this.getFilesList(res.data.submissionId)
            }
        }
    
        getFilesList = async (submissionId) => {
            try{
                this.setState({
                    showLoader : true,
                    action : "fetching",
                    loadTable : false
                })
                const res = await API.get(`/user/proposals3/getByid/${submissionId}`);
                this.setState({
                    showLoader : false,
                    action : "fetching",
                    loadTable : true,
                    fileList: res.data?.filter(file => file.type === "Translational")
                });
            }catch(e){
                console.log(e)
            }
        }

        setProposalFields = async () => {
            const obj = {}
            // Object.keys(DIAGNOSTIC_PROPOSAL).forEach(field => {
            //     obj[field] = this.state.proposalDetails[field] || ""
            // });
                          obj["target_start_date"]=this.state.proposalDetails.target_start_date ? dateStringformat(this.state.proposalDetails.target_start_date) : null ;
                          obj["target_complete_date"]=this.state.proposalDetails.target_complete_date ? dateStringformat(this.state.proposalDetails.target_complete_date) : null ;
                          obj["project_study_start_date"]=this.state.proposalDetails.project_study_start_date ?  dateStringformat(this.state.proposalDetails.project_study_start_date) : null ;
                          obj["project_study_complete_date"]=this.state.proposalDetails.project_study_complete_date ?  dateStringformat(this.state.proposalDetails.project_study_complete_date) : null ;
                          obj["prj_report_submission_date"]=this.state.proposalDetails.prj_report_submission_date ?   dateStringformat(this.state.proposalDetails.prj_report_submission_date) : null ;
                          obj["project_publication_date"]=this.state.proposalDetails.project_publication_date ?  dateStringformat(this.state.proposalDetails.project_publication_date) : null ;
            obj["is_accesss_to_lilly_data"]=this.state.proposalDetails.is_accesss_to_lilly_data === true ? "Yes" : "No"
            obj["is_preclinical_component"]=this.state.proposalDetails.is_preclinical_component === true ? "Yes" : "No"
            // obj["if_any_coinvestigators"]=this.state.proposalDetails.if_any_coinvestigators ? this.state.proposalDetails.if_any_coinvestigators === true ? "Yes" : "No" : ""
            // obj["unique_personal_clinical_sample"]=this.state.proposalDetails.unique_personal_clinical_sample === true ? "Yes" : "No"
            // obj["research_funding_request"]=this.state.proposalDetails.research_funding_request === true ? "Yes" : "No"
            // obj["final_study_report"]=this.state.proposalDetails.final_study_report === true ? "Yes" : "No"
           // obj["raw_data_requested"]=this.state.proposalDetails.raw_data_requested === true ? "Yes" : "No"
            obj["is_study_use_animal"]=this.state.proposalDetails.is_study_use_animal === true ? "Yes" : "No"
            obj["is_iacuc_doc_require"]=this.state.proposalDetails.is_iacuc_doc_require !== null ? (this.state.proposalDetails.is_iacuc_doc_require=== true ? "Yes" : "No"):""
            obj["is_animalcare_committee_approval"]=this.state.proposalDetails.is_animalcare_committee_approval !== null ? (this.state.proposalDetails.is_animalcare_committee_approval === true ? "Yes" : "No"):""
            obj["is_human_sample_involved"]=this.state.proposalDetails.is_human_sample_involved === true ? "Yes" : "No"
            obj["is_lilly_compound_requested"]=this.state.proposalDetails.is_lilly_compound_requested === true ? "Yes" : "No"
           // obj["shipping_import_license"]=this.state.proposalDetails.shipping_import_license === true ? "Yes" : "No"
    

            obj['submission_Id'] = `Submission-${this.state.proposalDetails.submission_id}`;
            obj['tumor_type'] = this.state.proposalDetails.tumor_type?.length>0 ? this.state.proposalDetails.tumor_type?.trim().split(';').join()?.split(',').map(obj => { return {value: obj,label: obj}}) : null;
            obj['primary_obj_hypothesis'] = this.state.proposalDetails.primary_obj_hypothesis || "";
            obj['secondary_objective'] = this.state.proposalDetails.secondary_objective || "";
            obj['scientific_rationale'] = this.state.proposalDetails.scientific_rationale || "";
            if(this.state.legal_represent_country?.name)
                this.getStateList(this.state.legal_represent_country.name, 'stateListLegal');
            if(this.state.coinvestigator_country?.name)
                this.getStateList(this.state.coinvestigator_country.name, 'stateListCoinvestigator');
                obj['createdByID'] = await getUserInfo(this.state.proposalDetails.created_by) +"  "+ dateTimeformat(this.state.proposalDetails.createdAt) || "";
                obj['LastModifiedByID'] = this.state.proposalDetails.updated_by?.length>0 ? await getUserInfo(this.state.proposalDetails.updated_by) +"  "+ dateTimeformat(this.state.proposalDetails.updatedAt) :  await getUserInfo(this.state.proposalDetails.created_by) +"  "+ dateTimeformat(this.state.proposalDetails.createdAt);
            this.setState({
                ...this.state.proposalDetails,
                ...obj
            })
        }
       
        getCountryList = async () => {
            const res = await API.get("seed/country/data");
            const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
            this.setState({
                countryList: resultArray,
                legal_represent_country: resultArray.find(cnt => ""+cnt.value === this.state.proposalDetails.legal_represent_country) || null,
                coinvestigator_country: resultArray.find(cnt => ""+cnt.value === this.state.proposalDetails.coinvestigator_country) || null
            }, this.setProposalFields);                
        }
    
        getStateList = async (country, name) => {
            const res = await API.get(`seed/states/data/${country}`);
            const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
            if(name === "stateListLegal")    
                this.setState({
                    [name]: resultArray,
                    legal_represent_state: resultArray.find(state => "" + state.value === this.state.proposalDetails.legal_represent_state) ||  ""
                });
            else
                this.setState({
                    [name]: resultArray,
                    coinvestigator_state: resultArray.find(state => ""+state.value === this.state.proposalDetails.coinvestigator_state) ||  ""
                });
        }
    
        onCountryChange = (e, name) => {
            this.setState({
                [name]: e,
                patchObject:  {...this.state.patchObject, [name]: e.value},
                hasChanged: true
            });
            if(name === "legal_represent_country")
                this.getStateList(e.name,"stateListLegal")
            else
                this.getStateList(e.name,"stateListCoinvestigator")
        }
    
      
        onInputChange = (e,eo) => {
            if (eo.type === "date") {
                let { name, value } = eo;
                this.setState({
                    [name]: e,
                    [value]: e,
                    patchObject: { ...this.state.patchObject, [eo.name]: dateChange(e)},
                    hasChanged: true
                });
            }
            else 
            { this.setState({
                [e.target.name]: e.target.value,
                patchObject:  {...this.state.patchObject, [e.target.name]: e.target.value},
                hasChanged: true
            });}
        }
    
        onSelectChange = (e, eo) => {
            this.setState({
                [eo.name || eo.target.name]: e,
                patchObject:  {...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join(";") : e},
                hasChanged: true
            });        
        }
    
        onSearchSelect = (e, name) => {
            this.setState({
                [name]: e,
                patchObject:  {...this.state.patchObject, [name]: e.value},
                hasChanged: true
            }); 
        }
    
        checkboxChange = (e) => {
            this.setState({
                [e.target.name]: e.target.checked,
                patchObject:  {...this.state.patchObject, [e.target.name]: e.target.checked},
                hasChanged: true
            });
        }
    
        getHtmlFromEditorValue = (val="") => {
            const tobesaved = convertToRaw(val.getCurrentContent());
            return stateToHTML(convertFromRaw(tobesaved));
        }
    
        getEditorValueFromHtml = (html="") => {
            if(html){
                const blocksFromHTML = convertFromHTML(html);        
                const state = ContentState.createFromBlockArray(blocksFromHTML);
                return EditorState.createWithContent(state);
            }
            return "";
        }
    
    
        onEditorChange = (val, e) => {
            this.setState({
                [e]: val,
                patchObject: {...this.state.patchObject, [e]: this.getHtmlFromEditorValue(val)},
                hasChanged: true
            });
        };
    
        renderInputFieldsInformation = () => {
            return this.inputFieldsArrayInformation.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                         disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
        renderInputFieldsLegalRepresentative = () => {
            return this.inputFieldsArrayLegalRepresentative.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
        renderInputFieldsCoInvestigator = () => {
            return this.inputFieldsArrayCoInvestigator.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
        // renderInputFieldsTargetDates = () => {
        //     return this.inputFieldsArrayTargetDates.map(field => {
        //         if (field.type === "alert")
        //             return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
        //         return (
        //         <Col sm={6} key={field.name || field.label}>
        //             <InputField 
        //                 {...field } 
        //                 disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
        //                 value = {this.state[field.name]}
        //                 onSelect={ field.onSelect || this.onSelectChange}
        //                  onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
        //             />
        //         </Col>
        //         )
        //     });            
        // }
        renderInputFieldsStudyTitle = () => {
            return this.inputFieldsArrayStudyTitle.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
        renderInputFieldsPrimaryObjectives = () => {
            return this.inputFieldsArrayPrimaryObjectives.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
        renderInputFieldsSecondaryObjectives = () => {
            return this.inputFieldsArraySecondaryObjjectives.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
        renderInputFieldsScientificRationale = () => {
            return this.inputFieldsArrayScientificRationale.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
        renderInputFieldsMethods = () => {
            return this.inputFieldsArrayMethods.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
        renderInputFieldsTestDetail = () => {
            return this.inputFieldsArrayTestDetail.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
        renderInputFieldsOperationalInformation = () => {
            return this.inputFieldsArrayOperationalInformation.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
        renderInputFieldsTimeline = () => {
            return this.inputFieldsArrayTimeline.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
        renderInputFieldsResearchFunding = () => {
            return this.inputFieldsArrayResearchFunding.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
        renderInputFieldsStudyReport = () => {
            return this.inputFieldsArrayStudyReport.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
        renderInputFieldsBriefDescription = () => {
            return this.inputFieldsArrayBriefDescription.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
        renderInputFieldsRawData = () => {
            return this.inputFieldsArrayRawData.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
         renderInputFieldsReferences = () => {
            return this.inputFieldsArrayReferences.map(field => {
                if (field.type === "alert")
                    return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
                return (
                <Col sm={6} key={field.name || field.label}>
                    <InputField 
                        {...field } 
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        value = {this.state[field.name]}
                        onSelect={ field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
                )
            });            
        }
        handleFormEvents=(option)=>{
            this.setState({activeForm:option});
        }
        handleModalCallBack=(response)=>{
            if(response===true){
                let callBack=(val)=>{
                    this.setState({activeForm:''},()=>{
                        //this.gettranslationalDetails(this.props.submissionId) ;
                        this.getTranslationDetails(this.props.submissionId);
                    });
                  }
                //   showMessage({                  
                //     text: "Saved Successfully",
                //     icon: "success",
                //     button: "Ok",
                  
                //   },callBack);
                callBack();
            } 
            else{
                this.setState({activeForm:''});
            }           
        }
        submitForm = async () => {
            if(this.formValidation())

            try {
                this.setState({historyRefresh:false});
                const res = await API.put("translationalProposal/update", {...this.state.patchObject, 'updated_by': this.context.userSystemId});
                this.setState({
                    successModal: true,
                    hasChanged: false,
                    historyRefresh:true,
                    error:{}
                });
            }catch(err){
                this.setState({errorModal: true,historyRefresh:true});
          }else{this.setState({errorModal:true})}
         }
         
         changeShowState = () => {
           
            if(!this.state.errorModal)
            this.getTranslationDetails(this.props.submissionId);
            this.setState({
                successModal: false,
                errorModal: false,
            successFile: false,
            errorFile: false,
            });
        }
    
        onNavOut = () => {
            this.props.history.push(`/submissions/${this.state.proposalDetails?.submissionId}`)
        }
        onFileChange = (e) => {
            this.setState({selectedFile: e.target.files[0]})
        }
    
        uploadFileFunction = async () => {
            this.setState({
                showLoaderUpload :true
            })
            const formData = new FormData();
            formData.append(        
            'file',
            this.state.selectedFile,
            this.state.selectedFile.name
            );
            try{
                // const res =await API.post(`/user/proposals3/upload/Translational/${this.state.proposalDetails.submissionId}`, formData);
                let fileUrl = `${this.state.externalurl}/fileupload/Translational/${this.state.proposalDetails.submissionId}`;
                let res = await axios.post(fileUrl, formData, {
                    headers: {                   
                        'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    },
                });
                this.setState({
                    selectedFile: null,
                    successFile: true,
                    fileMessage:"File Uploaded",
                    showLoaderUpload : false
                })

            }catch(e){
                this.setState({
                    selectedFile: null,
                    errorFile: true,
                    showLoaderUpload: false
                })
                console.log(e);
            }
        }
        handleTranslationalDelete = async (row) => {
            swal({
                // title: "Are you sure?",
                text: "Are you sure, you want to delete this record?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async(willDelete) => {
                    if (willDelete) {
                        try {
                            const res = await API.post(`recycle/translational/delete/${this.state.proposalDetails.id}/${this.state.proposalDetails.submissionId}`,{system_id : this.context.userSystemId});
                            this.setState({proposalDetails:{}});
                            swal("Deleted Successfully", {
                                icon: "success",
                            });
                            this.getTranslationDetails(this.props.submissionId);
                        }
                        catch (err) {
                            this.setState({ errorModal: true });
                        }
                        this.getTranslationDetails(this.props.submissionId)
                    }
                });
        }
        handleDelete = async (fileName) =>{
             swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async (willDelete) => {
            if (willDelete) {
                try {
                    this.setState({
                        showLoader : true , 
                        action : "delete"
                    })
                    const res = await API.post(`/user/proposals3/delete/Translational/${this.state.proposalDetails.submissionId}/${fileName}`, {updated_by : this.context.userSystemId});
                    
                    this.setState({
                        successFile: true,
                        fileMessage: "File deleted!",                        
                        showLoader : false
                    })

                }
                 catch (err) {
                    this.setState({ errorModal: true,
                    showLoader :  false });
                }
              
            } 
          });
           
         }
        downloadTemplate = async () => {
            try {
                this.setState({
                    showLoader: true,
                    action: "download"
                })
                const res = await API.get(`/proposal/diagnosticTranslational/generate/${this.state.proposalDetails.submissionId}/${false}/translational`,{
                    responseType: 'blob',
                    
                });
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute('download', `Submission-${this.state.proposalDetails.submissionId}_Translational_Form.docx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    successFile: true,
                    showLoader: false,
                    fileMessage: "File Downloaded Successfully!"
                })
            } catch (e) {
                this.setState({
                    errorFile: true,
                    showLoader: false
    
                })
            }
        }
         handleDownload = async (fileName) =>{
            try{
                this.setState({
                    showLoader : true , 
                    action : "download"
                })
               /* const res = await API.get(`/user/proposals3/download/getByid/Translational/${this.state.proposalDetails.submissionId}/${fileName}`,{
                    responseType: 'blob',
                });
                const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                this.setState({
                    successFile: true,
                    showLoader: false,
                    fileMessage:"File Downloaded Successfully!"
                })*/
                let fileUrl = `${this.state.externalurl}/filedownload/Translational/${this.state.proposalDetails.submissionId}?name=${fileName}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async(response) => {
                    if (response.ok) {
                       
                        return response.blob();
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            errorFile: true
                        });

                        throw new Error('Error Encountered.Please try again.');
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    this.setState({
                        successFile: true,
                        fileMessage: "File Downloaded Successfully!",
                        showLoader :  false
                    })
                });
            }catch(e){
                this.setState({
                    errorFile: true,
                    showLoader: false
                })
                console.log(e);
            }
         }
         handleStartClick=()=>{this.titleRef.current.scrollIntoView({ behavior: 'smooth',block:'center' })}
        handleBackClick=()=>{this.titleRef.current.scrollIntoView({ behavior: 'smooth' })}
        handlePrint=()=>{
            this.setState({isPrintMode:true},this.doPrint)
        }
        doPrint=()=>{
            let waitForCompletion=window.print();
            this.setState({isPrintMode:false})
        }
        render() {
            let { showLoaderUpload  ,loadTable, action ,  showLoader } = this.state;
            let _isCoordinator=this.state.isCoordinator;

            return (
                <div >  
                    <Accordion ref={this.titleRef} className="border rounded mb-2 shadow-sm"><Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>

                        <Accordion.Toggle as={Card.Header} eventKey="2" onClick={this.handleBackClick}>
                        <Row> <Col sm={8}><div className="flex-grow-1 font-weight-bold ">Translational Details</div>
                                            </Col> <Col sm={4} className="text-right">
                                                    <div className="d-flex justify-content-end align-items-center"> {_isCoordinator && this.state.access.edit && this.state.proposalDetails.submissionId && <IIRBtn.default className="d-flex align-items-center justify-content-center p-2 mr-2 " style="success w-100px" type="submit" onClick={(e)=>{this.submitForm(); e.preventDefault(); e.stopPropagation()}} disabled={!this.state.hasChanged}>
                                                        <span>SAVE</span>
                                                    </IIRBtn.default>}
                                                        {!this.state.proposalDetails.submissionId && _isCoordinator && <div className="d-flex justify-content-end align-items-center">

                                                            <DTAButton onClick={(e) => {this.handleFormEvents(this.formType.INITIATE_INSTRUCTION); e.preventDefault(); e.stopPropagation()}} style={{width:'230px'}} icon="ADD" text={"Add Translational Details"}></DTAButton>
                                                        </div>}
                                                        {/* {!this.state.proposalDetails.submissionId&&<i className="bi bi-plus " style={{fontsize:"2rem"}}></i>} */}
                                                        {this.state.proposalDetails.submissionId && _isCoordinator && <div>
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
                                                                    <span className="material-icons mr-2" >
                                                                        more_vert
                                                                    </span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="bg-dark text-white" >
                                                                    <Dropdown.Item> <div onClick={(e) => {
                                                                        e.preventDefault(); e.stopPropagation();
                                                                        this.handlePrint(); }} className="text-white">Print</div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item> <div onClick={(e)=>{e.preventDefault(); e.stopPropagation();
                                                                    this.downloadTemplate(); }}className="text-white">Download Translational Form</div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item> <div onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.handleTranslationalDelete(); }} className="text-white">Delete</div>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </div>}


                                                    </div>


                                                </Col></Row>
                                            {/* <span className="alert-line"></span> */}
                                            
                        </Accordion.Toggle>
{this.state.activeForm===this.formType.INITIATE_INSTRUCTION && <NewTranslationalEx  submissionId={this.props.submissionId} show={true} onClose={this.handleModalCallBack} ></NewTranslationalEx>}

                    <Accordion.Collapse eventKey="2">
    <Card.Body>
    {this.state.proposalDetails.submissionId&& <div> <Row>{this.renderInputFieldsInformation()}</Row>
                <span className="alert-line"></span>
                <div className="flex-grow-1 font-weight-bold mb-3"> Legal Representative</div>
                <Row> {this.renderInputFieldsLegalRepresentative()}</Row>
                <span className="alert-line"></span>
                <div className="flex-grow-1 font-weight-bold mb-3">  C. Co-Investigator(s) and Location</div>
               <Row> {this.renderInputFieldsCoInvestigator()}</Row> 
               <span className="alert-line"></span>
               {/* <div className="flex-grow-1 font-weight-bold mb-3"> Target Dates for Starting and Completing Study</div>
                <Row> {this.renderInputFieldsTargetDates()}</Row>
                <span className="alert-line"></span> */}
               <div className="flex-grow-1 font-weight-bold mb-3">  Study Title</div>
                <Row>{this.renderInputFieldsStudyTitle()}</Row>
                <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3">Primary Objective/Hypothesis</div>
               <Row> {this.renderInputFieldsPrimaryObjectives()}</Row>
               <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3">  Secondary Objective(s)</div>
               <Row> {this.renderInputFieldsSecondaryObjectives()}</Row>
               <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3"  > Scientific Rationale (no more than 1 page; include references)</div>
               
               <Row> {this.renderInputFieldsScientificRationale()}</Row>
               <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3"  >  Methods: Study Design including Endpoints and Statistical Analysis</div>
               
               <Row>  {this.renderInputFieldsMethods()}</Row>
               <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3">  Study Details</div>
               <Row> {this.renderInputFieldsTestDetail()}</Row>
               <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3">  Operational Information</div>
               <Row> {this.renderInputFieldsOperationalInformation()}</Row>
               <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3">  Timeline (target dates for starting and completing study)</div>
               <Row> {this.renderInputFieldsTimeline()}</Row>
               <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3"> Research Funding Request:</div>
               <Row>  {this.renderInputFieldsResearchFunding()}</Row>
               <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3">Study Report Request</div>
               <Row> {this.renderInputFieldsStudyReport()}</Row>
               <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3"> Brief Description of Publication Strategy</div>
               <Row> {this.renderInputFieldsBriefDescription()}</Row>
               <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3"> Raw Data Request</div>
               <Row> {this.renderInputFieldsRawData()}</Row>
               <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3">  References</div>
               <Row> {this.renderInputFieldsReferences()}</Row>
               <span className="alert-line"></span></div>}

               {this.state.proposalDetails.submissionId&&  <span className="flex-grow-1 font-weight-bold mb-3">File Upload</span>}
{this.state.proposalDetails.submissionId&& <Row>
    <Col sm={12}><div  >
               <Row className="mt-4 hidePrint" >
                    <Col sm={12}>
                        
                           
                            {this.state.access.edit && <Row>
                                <Col sm={8}>
                                <div className="ml-3 position-relative">
                                    <Form.File
                                        id="custom-file"
                                        className="custom-file-label ml-0"
                                        onChange={this.onFileChange}
                                        label={this.state.selectedFile?.name}
                                        custom
                                    />
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    {showLoaderUpload && <Button className="btn btn-primary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </Button>}
                                    {!showLoaderUpload && <Button variant="secondary" onClick={()=> uploadFile(this.state.selectedFile,this.state.fileList,this.uploadFileFunction)} disabled={this.state.selectedFile === null} >Upload</Button>}
                                </Col>
                            </Row>}
                            {this.state.access.edit && <small className="form-text text-muted pl-3">*Maximum size for file upload is 2MB.</small>}
                            <br />
                            {showLoader &&
                                <Row>
                                    <div className="mt-3 btn btn-muted fs-1 bg-transparent text-primary" disabled>
                                        <span className="visually-hidden">File {action} in progress </span>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </div>

                                </Row>}
                            {this.state.fileList.length > 0 && loadTable && 
                                <Row> <Col sm={12} className='p-4 w-100'><FileComponentEx data={this.state.fileList}
                                    accessDelete={this.state.access.delete}

                                    handleDelete={this.handleDelete}
                                    handleDownload={this.handleDownload} /></Col></Row>
                            }

                        
                    </Col>
                </Row>
                <span className="alert-line"></span>
                </div></Col></Row>}
                {this.state.proposalDetails&&this.state.proposalDetails.id&&this.state.historyRefresh&&<Row className="mt-4">
                    <Col sm={12}>
                            <span className="flex-grow-1 font-weight-bold">History</span>
                            <SwitchHistory 
                            object_type={OBJ_TYPES.TRANSLATIONAL_PROPOSAL} 
                            object_id={this.state.proposalDetails.id} 
                            /> 
                    </Col>
                </Row>}
    </Card.Body>
                    </Accordion.Collapse>
</Card>
</Accordion>
                    
                    
                    
                    
                    <div className="new-flow-modal">
                <br/>

               {/* <div className="w-100 d-flex justify-content-center">
                        {this.state.access.edit && <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                            Save
                        </Button>}
                    </div> */}
                    {/* <DatatTableEx
                    pageIcons={[<i className="bi bi-plus"></i>]}
                    /> */}
                    {this.state.successModal &&
                    <BootstrapModal 
                        show={this.state.successModal} 
                        header="Success"
                        body="Translational Proposal has been Updated!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal 
                        show={this.state.errorModal} 
                        variant="danger"
                        header="Ooops!!"
                        body={<><div>An error occured! Please try again later!</div>{this.getErrorDescriptions()}</>}
                       
                        changeShowState={this.changeShowState}
                    />
                }
                 {this.state.successFile &&
                    <BootstrapModal
                        show={this.state.successFile}
                        header="Success"
                        body={this.state.fileMessage}
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorFile &&
                    <BootstrapModal
                        show={this.state.errorFile}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
           
                                    

                
                                  
                </div></div>
            )
        }
}
