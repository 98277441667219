import React, { useState, useEffect } from "react";
import DatatTableEx from "../DataTableEx";
import API from "../../../api/apis";
import { iirDateFormat } from "../../../utility/dateHelper";
// import { dateTimeformat } from '../../../utility/helperFunctions';

const SwitchHistory = (props) => {
  const [data, setData] = useState([]);
  const [showLoader, setLoader] = useState(true);
  
  const columns = [
    {
      dataField: "id",
      text: "id",
      sort: true,
      hidden: true,
      excludeFilter:true,
      csvExport: false,
    },
    {
      dataField: "created_updated_at",
      text: "Date",
      sort: true,
      sortValue: (cell, row) => new Date(row["created_updated_at"]),
    },
    {
      dataField: "field_label",
      text: "Field",
      sort: true,
    },
    {
      dataField: "created_updated_by",
      text: "User",
      sort: true,
    },
    {
      dataField: "old_value",
      text: "Original Value",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return <>{row.old_value != null ? row.old_value : ""}</>;
      },
    },
    {
      dataField: "new_value",
      text: "New Value",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return <>{row.new_value != null ? row.new_value : ""}</>;
      },
    },
  ];

  useEffect(async () => {
    getHistoryData();
  }, []);

  const getColumns = () => {
    let columnsEx  = columns;
    let addntlColumns = [];
    if(props.showRowType){
      addntlColumns = [
        {
          dataField: "row_type",
          text: "Row Type",
          sort: true,
          formatter: (cell, row, rowIndex) => {
            return <>{row.row_type != null ? row.row_type : ""}</>;
          },
        },
      ]
    }
    return [...columnsEx, ...addntlColumns];
  }
  const getHistoryData = async () => {
    try {
      const payload = {
        object_id: props.object_id,
        object_type: props.object_type,
      };
      let res = await API.post(`audit/common/services`, payload);

      if (res && res.data) {
        let formatValues = res.data.map((item, index) => {
          let { created_updated_at, action, old_value, new_value, field_label, field, ...otherItems } = item;
  
          created_updated_at = created_updated_at != null ? iirDateFormat(created_updated_at, true) : "";
  
            if( ['date_needed', // Drug Order
              'date_requested', // Drug Order
              'estimated_delivery_date', // Drug Order
              'targetStartingDate', // Diagnostic
              'targetCompletingDate', // Diagnostic
              'projectedStudyStartDate', // Diagnostic
              'projectedStudyCompleteDate', // Diagnostic
              'projectedDateForStudyReportSubmission', // Diagnostic
              'projectedDateForPublication',  // Diagnostic
              'project_study_start_date', // Translational
              'project_study_complete_date', // Translational
              'prj_report_submission_date', // Translational
              'project_publication_date', // Translational
              'targetStartDate', // Proposal
              'reportSubmissionDate', // Proposal
              'publicationDateAnticipated', // Proposal
              'dateOfFirstPatientVisit', // Proposal
              'dateFirstPtEntersTreatment', // Proposal
              'dateLastPatientEntersTrial', // Proposal
              'dateLastPatientEntersTreatment', // Proposal
              'dateOfLastPatientVisit', // Proposal
              'dateOfReportSubmission', // Proposal
              'dateOfPublicationSubmission' // Proposal

            ].includes(field) ){
              old_value = old_value != null? iirDateFormat(old_value) : "";
              new_value = new_value != null ? iirDateFormat(new_value) : ""
          } 
          if( field_label === 'Updated At' || 
            ['accept_privacy_statement_attested_date', // Delegee
              'no_confidential_info_attested_date', // Delegee
              'working_on_sponsor_behalf_attested_date', // Delegee
              'no_sensitive_personal_info_attested_date'  // Delegee
            ].includes(field) 
            ){
            old_value = old_value != null? iirDateFormat(old_value, true) : "";
            new_value = new_value != null ? iirDateFormat(new_value, true) : ""
          }
          if( field_label === 'Created.' ||
              field === 'created_at' ){
                
            new_value = new_value != null? iirDateFormat(new_value, true) : "";
          }
  
          return {
            created_updated_at, action, old_value, new_value, field_label, field, ...otherItems
          }
        })
        setData(formatValues);
      }
    } catch (e) {
      console.error("Error in fetching History data: ", e);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>

      <DatatTableEx
        filterChange={()=>{}}
        autoFilter={true}
        columns={getColumns()}
        csvName={`${props.object_type}_history_details`}
        pagination="true"
        data={data}
        showLoader={showLoader}
      />
    </div>
  );
};
export default SwitchHistory;
