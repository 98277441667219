import React from "react";
import DatatTableEx from "../../../common/DataTableEx";
import { dateStringformat, dateTimeformat } from "../../../../utility/helperFunctions";
import { Col, Row } from 'react-bootstrap';
import API from "../../../../api/apis";
export default class HistoryList extends React.Component{
    constructor(props){
        super(props);    
        this.state={
            auditData:[]
        }    
    }
    async componentDidMount(){
        await this.fetchStaticData();
        await this.getAuditList();
    }
    componentDidUpdate(prevProps,prevState){
        if(prevProps.historyData!=this.props.historyData){
            this.getAuditList();  
        }
    }
    columns = [
        {
            dataField: 'id',
            text: 'id',
            hidden: true,
            csvExport:false
        },
        {
        dataField: 'created_at',
        text: 'Date',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.updatedAt?.length > 0 ? dateTimeformat(row.updatedAt) : ""}</p>

            </>
        }
    }, {
        dataField: 'Field',
        text: 'Field',
        sort: true
    },{
        dataField: 'updater.first_name',
        text: 'User',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.updater?.first_name !=null && row.updater?.last_name!=null ?row.updater.first_name + " " + row.updater.last_name : row.creater.first_name + " " + row.creater.last_name}</p>

            </>
        }


    },
    {
        dataField: 'old_value',
        text: 'Original Value',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.old_value !=null ? (row.old_value):""}</p>

            </>
        }
    }, {
        dataField: 'new_value',
        text: 'New Value',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.new_value !=null ? (row.new_value):""}</p>

            </>
        }
    }];
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/site`)           
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
    getAllCountryList = async () => {
        const res = await API.get("seed/country/getall");
        this.setState({ countryAllList: res.data.data });

    }
    getAllStateList = async () => {      
            const res = await API.get(`seed/state/list`);
            this.setState({ 
                stateAllList: res.data,
            });
    }
    getUserList = async () => {
        const res = await API.get(`user/data`)
       
        this.setState({usersList: res.data});
    }
    fetchStaticData=async()=>{
        await this.getTrailList();
        await this.getUserList();
        await this.getAllCountryList();
        await this.getAllStateList();
    }
    getAuditList = async (id) => {
        try {
            let temp = [];
            
            const res = this.props.historyData||[];
           
            this.state.trailData.map(x => {
                this.props.historyData.map(y => {
                    if (x.key.toLowerCase() === y.field.toLowerCase()) {
                        y["Field"] = x.label;
                        if(y.field==="updated_by"){
                           let newValueText=y.new_value!== null ? this.state.usersList.filter(x=> x.system_id===(y.new_value))[0] : "" ;
                        y.new_value=newValueText!=="" ? newValueText?.first_name+" "+newValueText?.last_name : "";
                      let  oldValueText=y.old_value!==null ? this.state.usersList.filter(x=> x.system_id===(y.old_value))[0] :"";
                        y.old_value=oldValueText!=="" ? oldValueText?.first_name+" "+oldValueText?.last_name : "";
                            temp.push(y);
                        }
                        else if(y.field==="country_id")
                        {
                            let newValueText=y.new_value!==null ? this.state.countryAllList.filter(x=> x.id+""===y.new_value)[0] :"" ;
                        y.new_value=newValueText!=="" ? newValueText.name : "";
                      let  oldValueText=y.old_value!== null ? this.state.countryAllList.filter(x=> x.id+""===y.old_value )[0] :"";
                        y.old_value=oldValueText!=="" ? oldValueText.name : "";
                            temp.push(y);
                        }
                        else if(y.field==="state_province_id")
                        {
                            let newValueText=y.new_value!==null ? this.state.stateAllList.filter(x=> x.id+""===y.new_value)[0] :"" ;
                        y.new_value=newValueText!=="" ? newValueText.name : "";
                      let  oldValueText=y.old_value!== null ? this.state.stateAllList.filter(x=> x.id+""===y.old_value )[0] :"";
                        y.old_value=oldValueText!=="" ? oldValueText.name : "";
                            temp.push(y);
                        }
                        else if(y.field==="created_at" ){
                            y.new_value=y.new_value!== null ? dateTimeformat(y.new_value):""
                            y.old_value=y.old_value!== null ? dateTimeformat(y.old_value):"";
                             temp.push(y);
                         }
                         else if( y.field==="irb_erb_expiration_date" || y.field==="irb_erb_start_date"){
                            y.new_value=y.new_value!== null ? dateStringformat(y.new_value):"";
                            y.old_value=y.old_value!== null ? dateStringformat(y.old_value):"";
                             temp.push(y);
                         }
                   else {
                    y.new_value=(y.new_value);
                    y.old_value=(y.old_value)
                       temp.push(y);}
            
                    }

                })
            })
            
            let sortedReviews = temp.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            this.setState({
                auditData: sortedReviews,
            })

        }
        catch (err) {
            console.log(err)
        }
    }
    render(){
        const {historyTitle, historyData}= this.props;
        return (
            <Row>
                <Col md={7}>
                    <h5 class="table-top font-weight-bold">
                    {historyTitle}</h5>
                </Col>
                <DatatTableEx id="historyFiles" data={this.state.auditData||[]} columns={this.columns}  pagination="true" />
            </Row>
        )
    }
}